import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsPencilSquare, BsCheck2 } from "react-icons/bs";
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';

const TodoList = () => {
  const { user } = useAuth();
  const [todos, setTodos] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editedTask, setEditedTask] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchTodos();
    // eslint-disable-next-line
  }, []);

  //Fetch Todo
  const fetchTodos = () => {
    axios.post(`${apiUrl}/skripte/todo.php`, {
      action: 'fetch',
      username: user.username
    })
    .then(response => {
      if (Array.isArray(response.data)) {
        setTodos(response.data);
      } else {
        // Obrada u slučaju da odgovor nije niz
        setTodos([]);
        console.error('Očekivani niz, dobiveno:', response.data);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
  };

  //Add todo
  const addTodo = () => {
    // Provjerava je li newTask prazan ili sastavljen samo od praznih mjesta
    if (!newTask.trim()) {
      toast.error('Task cannot be empty!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Prekida izvođenje funkcije ako je uvjet ispunjen
    }
  
    const currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
  
    axios.post(`${apiUrl}/skripte/todo.php`, {
      action: 'add',
      text: newTask,
      username: user.username,
      date: currentDate
    })
    .then(response => {
      setNewTask("");
      fetchTodos();
      toast.success('Task added!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    })
    .catch(error => {
      toast.error('Error', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  };
  
  //Edit todo
  const editTodo = (id, newText) => {
    axios.post(`${apiUrl}/skripte/todo.php`, {
      action: 'update',
      id: id,
      text: newText
    })
    .then(response => {
      fetchTodos();
      toast.success('Task updated!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    })
    .catch(error => {
      toast.error('Error', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  };

  const handleEdit = (id, task) => {
    setEditingId(id);
    setEditedTask(task);
  };

  const handleUpdate = (id) => {
    // Provjerava je li editedTask prazan ili sastavljen samo od praznih mjesta
    if (!editedTask.trim()) {
      toast.error('Task cannot be empty!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Prekida izvođenje funkcije ako je uvjet ispunjen
    }
    editTodo(id, editedTask);
    setEditingId(null);
    setEditedTask('');
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditedTask('');
  };
  

  //Delete Todo
  const deleteTodo = (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this todo?");
    if (isConfirmed) {
      axios.post(`${apiUrl}/skripte/todo.php`, {
        action: 'delete',
        id: id
      })
      .then(response => {
        fetchTodos();
        toast.success('Task deleted!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
        toast.error('Error', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  };
  

  return (
    <div>
      <div className="v-alignment">
        <input
          type="text"
          value={newTask}
          onChange={(e) => {
            if (e.target.value.length <= 60) {
              setNewTask(e.target.value);
            }
          }}
          maxLength={60}
        />
        <button className="primary-button" onClick={addTodo}>Add</button>
      </div>
      <div className="list v-alignment">
      <ul>
        {todos.map(todo => (
          <li key={todo.id}>
            {editingId === todo.id ? (
              <div className="todo-list">
                <div className="todo-left">
                  <form>
                    <input
                      type="text"
                      value={editedTask}
                      onChange={(e) => setEditedTask(e.target.value)}
                      required
                    />
                    <button className="primary-button" onClick={() => handleUpdate(todo.id)}>
                      Save
                    </button>
                  </form>
                  
                </div>
                <div className="todo-right">
                  
                  
                  <button className="red-button" onClick={handleCancelEdit}>
                    Cancel
                  </button>
                </div>  
              </div>
            ) : (
              <div className="todo-list">
                <div className="todo-left">
                  {todo.task}
                  <p><i>{todo.datum}</i></p>
                </div>
                <div className="todo-right">
                  <button className="action-button" onClick={() => handleEdit(todo.id, todo.task)}>
                    <BsPencilSquare />
                  </button>
                  <button className="action-button" onClick={() => deleteTodo(todo.id)}>
                    <BsCheck2 />
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
      </div>
    </div>
  );
};

export default TodoList;
