import React, { useState } from 'react';

const General = ({ tripData, setTripData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTripData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div>
      <h2>General</h2>
      <label>
        Naziv:
        <input type="text" name="naziv" value={tripData.naziv} onChange={handleChange} />
      </label>
      <br />
      <label>
        Lokacija:
        <input type="text" name="lokacija" value={tripData.lokacija} onChange={handleChange} />
      </label>
    </div>
  );
};

export default General;
