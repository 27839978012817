import React, { useState } from 'react';

const Screen3 = ({ tripData, setTripData }) => {
  const [carOwner, setCarOwner] = useState('');
  const [carCount, setCarCount] = useState(0);

  const addCarOwner = () => {
    if (carOwner && !tripData.carOwners.includes(carOwner)) {
      setTripData((prevData) => ({
        ...prevData,
        carOwners: [...prevData.carOwners, carOwner],
      }));
      setCarOwner('');
    }
  };

  return (
    <div>
      <h2>Automobili</h2>
      <label>
        Broj Automobila:
        <input
          type="number"
          value={carCount}
          onChange={(e) => setCarCount(e.target.value)}
        />
      </label>
      <button onClick={() => setTripData((prevData) => ({ ...prevData, carCount }))}>
        Postavi Broj Automobila
      </button>
      <br />
      <label>
        Vlasnik Automobila:
        <select value={carOwner} onChange={(e) => setCarOwner(e.target.value)}>
          <option value="">Izaberi Vlasnika</option>
          {tripData.people.map((p, index) => (
            <option key={index} value={p}>
              {p}
            </option>
          ))}
        </select>
      </label>
      <button onClick={addCarOwner}>Dodaj Vlasnika</button>
      <ul>
        {tripData.carOwners.map((owner, index) => (
          <li key={index}>{owner}</li>
        ))}
      </ul>
    </div>
  );
};

export default Screen3;
