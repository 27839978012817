import React from "react";

const Activity = () => {
    return (
        <div>
            <p>There is no recent activity.</p>
        </div>
    );
    
}
export default Activity;