import React from "react";

const Menu = () => {
    return (
        <div style={{display: 'flex', gap: '10px'}}>
            <button className="community-menu-btn">Recent</button>
            <button className="community-menu-btn">Agenda</button>
            <button className="community-menu-btn">Events</button>
            <button className="community-menu-btn">Trips</button>
        </div>
    );
    
}
export default Menu;