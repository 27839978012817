import React, { useState } from 'react';
import General from './General';
import Screen2 from './Screen2';
import Screen3 from './Screen3';

const TripPlanner = () => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [tripData, setTripData] = useState({
    naziv: '',
    lokacija: '',
    people: [],
    carCount: 0,
    carOwners: [],
  });

  const nextScreen = () => {
    setCurrentScreen((prev) => prev + 1);
  };

  const prevScreen = () => {
    setCurrentScreen((prev) => prev - 1);
  };

  const createTrip = () => {
    // Logika za dodavanje u bazu podataka
    console.log(tripData);
    // Ovde možete dodati kod za slanje podataka na backend
  };

  return (
    <div>
      <h1>Trip Planner</h1>
      {currentScreen === 1 && <General tripData={tripData} setTripData={setTripData} />}
      {currentScreen === 2 && <Screen2 tripData={tripData} setTripData={setTripData} />}
      {currentScreen === 3 && <Screen3 tripData={tripData} setTripData={setTripData} />}

      <div>
        {currentScreen > 1 && (
          <button className="primary-button" onClick={prevScreen}>
            Previous
          </button>
        )}
        {currentScreen < 3 ? (
          <button className="primary-button" onClick={nextScreen}>
            Next
          </button>
        ) : (
          <button className="primary-button" onClick={createTrip}>
            Create
          </button>
        )}
      </div>
    </div>
  );
};

export default TripPlanner;

