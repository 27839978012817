import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import { useAuth } from './AuthContext';
import { CiLock } from "react-icons/ci";
import { toast } from 'react-toastify';

// Postavka za modal, obično se stavlja na početak aplikacije
Modal.setAppElement('#root');

const Account = ({ isOpen, closeModal }) => {
  const { logout, user, updateUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState(null);
  const fileInput = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;


  //Dohvacanje iz baze podataka
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/skripte/account.php?username=${user.username}`);
      if (!response.ok) {
        throw new Error('Problem pri dohvaćanju podataka');
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Došlo je do greške: ', error);
    }
  }  

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  //Logout
  const handleLogout = () => {
    logout();
  };

  //Password
  const handleSendResetPasswordEmail = () => {
    const userEmail = user.email; // Pretpostavka da imate korisnički email u 'user' objektu
    const formData = new URLSearchParams();
    formData.append('email', userEmail);

    fetch(`${apiUrl}/skripte/password_reset.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('Reset password link has been sent to your email.');
      } else {
        alert(data.message || 'Failed to send reset password link.');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while sending reset password link.');
    });
  };  

  const handleEdit = () => {
    setIsEditing(true);
  };

  //Update account
  const handleSave = async () => {
    try {
      const payload = {
        update: true,
        id: data.user.id, 
        email: data.user.email,
        bio: data.user.bio,
        work: data.user.work,
      };
  
      const response = await fetch(`${apiUrl}/skripte/account.php`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Problem pri ažuriranju podataka');
      }
  
      const responseData = await response.json();
      if (responseData.success) {
        setData(prevData => ({
          ...prevData,
          user: {
            ...prevData.user,
            email: payload.email,
            bio: payload.bio,
            work: payload.work,
          },
        }));
        setIsEditing(false);
        fetchData();
        toast.success('Account updated!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error('Error', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.error('Ažuriranje nije uspjelo: ', responseData.message);
      }
    } catch (error) {
      console.error('Došlo je do greške: ', error);
      toast.error('Error', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleCancel = () => {
    setData(data);
    setIsEditing(false);
  };

  //Profile photo
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size < 3 * 1024 * 1024) { // Provjera veličine datoteke (3 MB)
      // Ovdje implementirajte logiku za slanje datoteke na server
      uploadFile(file);
    } else {
      alert('File is too large. The limit is 3 MB.');
    }
  };
  
  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('username', user.username);
  
    fetch(`${apiUrl}/skripte/upload.php`, {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        updateUser({ ...user, image: data.imageName });
      } else {
        toast.error('Failed to upload image!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('Error while uploading image.');
    });
  };

  //Dizajn modala
  const modalStyle = {
    content: {
      width: '80%',
      height: '80%',
      border: '1px solid #212529',
      background: '#343a40',
      overflow: 'auto',
      borderRadius: '10px',
      outline: 'none',
      padding: '20px',
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
  }; 

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Account Modal"
      style={modalStyle}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      {data && (
      <div className="profile-modal">
        <div className="profile-image">
        <div className="profile-photo" onClick={() => fileInput.current.click()}>
        {user && (
        <>
          <img 
            src={user.image ? `${apiUrl}/skripte/uploads/${user.image}?${new Date().getTime()}` : require('../img/default.png')} 
            alt="Profile_photo" 
          />
          <div className="photo-overlay">Change Photo</div>
        </>
        )}
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            ref={fileInput}
            accept="image/*"
          />
        </div>
          <div className="username">@{data.user.username}</div>
          <h2>{data.user.ime} {data.user.prezime}</h2>
        </div>
        <div className="profile-details">
          <h1>Account</h1>
          <div className="detail">
          <span className="label">Email:</span>
            {isEditing ? (
              <input
                type="text"
                value={data?.user.email || ''}
                onChange={(e) => {
                  const newEmail = e.target.value;
                  if (newEmail.length <= 30) {
                    setData(prevData => ({
                      ...prevData,
                      user: { ...prevData.user, email: newEmail }
                    }));
                  }
                }}
                maxLength={30}
              />
            ) : (
              <span className="value">{data?.user.email}</span>
            )}
            {isEditing ? (
              <>
                <button className="action-button" onClick={handleSave}>Save</button>
                <button className="action-button" onClick={handleCancel}>Cancel</button>
              </>
            ) : (
              <button className="action-button" onClick={handleEdit}>Edit</button>
            )}
          </div>
          <div className="detail">
            <span className="label">Password:</span>
            <span className="value">••••••••</span>
            <button className="action-button" onClick={handleSendResetPasswordEmail}>Send email URL</button>
          </div>
          <hr />
          <div className="detail">
          <span className="label">Bio:</span>
            {isEditing ? (
              <input
                type="text"
                value={data?.user.bio || ''}
                onChange={(e) => {
                  const newBio = e.target.value;
                  if (newBio.length <= 90) {
                    setData(prevData => ({
                      ...prevData,
                      user: { ...prevData.user, bio: newBio }
                    }));
                  }
                }}
                maxLength={90}
              />
            ) : (
              <span className="value">{data?.user.bio}</span>
            )}
            {isEditing ? (
              <>
                <button className="action-button" onClick={handleSave}>Save</button>
                <button className="action-button" onClick={handleCancel}>Cancel</button>
              </>
            ) : (
              <button className="action-button" onClick={handleEdit}>Edit</button>
            )}
          </div>
          <hr />
          <div className="detail">
          <span className="label">Work:</span>
            {isEditing ? (
              <input
                type="text"
                value={data?.user.work || ''}
                onChange={(e) => {
                  const newWork = e.target.value;
                  if (e.target.value.length <= 80) {
                    setData(prevData => ({
                      ...prevData,
                      user: { ...prevData.user, work: newWork }
                    }));
                  }
                }}
                maxLength={80}
              />
            ) : (
              <span className="value">{data?.user.work}</span>
            )}
            {isEditing ? (
              <>
                <button className="action-button" onClick={handleSave}>Save</button>
                <button className="action-button" onClick={handleCancel}>Cancel</button>
              </>
            ) : (
              <button className="action-button" onClick={handleEdit}>Edit</button>
            )}
          </div>

          {isEditing ? (
            <>
            </>
          ) : (
            <div style={{display: 'flex', gap: '10px'}}>
              <button className="primary-button" onClick={closeModal}>Close</button>
              <button className="red-button" onClick={handleLogout}>Logout</button>
            </div>
          )}
          
        </div>
      </div>
      )}

      <div className="modal-footer">
        v1.0 | <CiLock /> All of your data is safe and encrypted
      </div>
    </Modal>
  );
}

export default Account;
