import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext';
import { ToastContainer } from 'react-toastify';

import Navbar from './components/navbar';
import Header from './components/header';
import Agenda from './components/agenda';
import TodoList from './components/TodoList';
import Footer from './components/footer';
import Login from './screens/Login';
import Register from './screens/Register';
import Community from './screens/Community';
import CommunityGroup from './screens/Community-group';

import './css/style.css';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
  const { user } = useAuth();

  return (
    <>
      {user && <Navbar />}
      {user && <Header />}
      <main className="home-main">
        <div className="content">
          <Agenda />
        </div>
        <div className="content">
          <h3>To do list</h3>
          <TodoList />
        </div>
        <ToastContainer />
      </main>
    </>
  );
};

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

const MainContent = () => {
  return (
    <div className="main-content">
      <Routes>
        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/community" element={<PrivateRoute><Community /></PrivateRoute>} />
        <Route path="/community-group/:groupId" element={<PrivateRoute><CommunityGroup /></PrivateRoute>} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <MainContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
