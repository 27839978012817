import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom'

import { AiOutlinePlusCircle, AiFillCloseCircle } from "react-icons/ai";

const GroupFriends = () => {
    const { user } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get(`${apiUrl}/skripte/groups.php?username=${user.username}`);
                if (response.data.success) {
                    setGroups(response.data.groups);
                } else {
                    alert(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
                alert('An error occurred while fetching groups.');
            } finally {
                setLoading(false);
            }
        };

        if (user.username) {
            fetchGroups();
        }
        // eslint-disable-next-line
    }, [user.username]);

    const handleGroupClick = (groupId) => {
        navigate(`/community-group/${groupId}`); 
    };

    //Group create
    const [groupName, setgroupName] = useState('');
    const [groupDesc, setgroupDesc] = useState('');
    const [inviteInput, setInviteInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const fetchSuggestions = async (query) => {
        if (query.length >= 3) {
            const response = await axios.get(`${apiUrl}/skripte/account.php?q=${query}`);
            setSuggestions(response.data.users || []);
        } else {
            setSuggestions([]);
        }
    };

    const handleInviteChange = (e) => {
        const value = e.target.value;
        setInviteInput(value);
        fetchSuggestions(value);
    };

    const handleUserSelect = (user) => {
        if (!selectedUsers.some(u => u.id === user.id)) {
            setSelectedUsers([...selectedUsers, user]);
        }
        setInviteInput('');
        setSuggestions([]);
    };

    const handleUserRemove = (userId) => {
        setSelectedUsers(selectedUsers.filter(u => u.id !== userId)); // Remove user from selectedUsers
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${apiUrl}/skripte/groups.php`, {
                groupName,
                groupDesc,
                selectedUsers
            });

            if (response.data.success) {
                toast.success('Group created!', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                ClearInputs();
                closeModal();
            } else {
                toast.error('Failed to create group!', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.error('Error saving the group:', error);
            alert('An error occurred while saving the group.');
        }
    };


    //Dizajn modala
    const modalStyle = {
        content: {
            width: '40%',
            height: '60%',
            border: '1px solid #1488df',
            background: '#0d1b2a',
            overflow: 'auto',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
    };

    const ClearInputs = () => {
        setgroupName('');
        setSelectedUsers([])
        setgroupDesc('')
    }

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => {
        setModalIsOpen(false);
    }
    const DiscardModal = () => {
        setModalIsOpen(false);
        ClearInputs();
        closeModal();
    }

    return (
        <div>
            <div>
                <h3>Groups</h3>
                <button className="action-button-3" onClick={openModal}><AiOutlinePlusCircle /> Create new gorup</button>
                <Modal
                    isOpen={modalIsOpen}
                    shouldCloseOnOverlayClick={false}
                    onRequestClose={closeModal}
                    contentLabel="Create Group"
                    style={modalStyle}
                >
                    <button onClick={closeModal} className="action-button" style={{ right: '20px', fontSize: '35px', position: 'absolute' }}><AiFillCloseCircle /></button>
                    <div className="modal-content">
                        <h2>Create new group</h2>
                        <form>
                            <input type="text" placeholder="Name of group" value={groupName} onChange={e => setgroupName(e.target.value)} />
                            <textarea placeholder="Description of gorup" value={groupDesc} onChange={e => setgroupDesc(e.target.value)} />

                            <input type="text" placeholder="Add members" value={inviteInput} onChange={handleInviteChange} />
                            <ul className="person-list">
                                {selectedUsers.map(user => (
                                    <li className="person-selected" key={user.id}>
                                        <img
                                            src={user.image ? `${apiUrl}/skripte/uploads/${user.image}` : require('../../img/default.png')}
                                            alt="Profile"
                                        />
                                        @{user.username} - {user.firstName} {user.lastName}
                                        <button onClick={() => handleUserRemove(user.id)} className="action-button" style={{ marginLeft: '10px', cursor: 'pointer' }} title="Remove"><AiFillCloseCircle /></button>
                                    </li>
                                ))}
                                {suggestions
                                    .filter(user => !selectedUsers.some(u => u.id === user.id))
                                    .map(user => (
                                        <li className="person-unselected" key={user.id} onClick={() => handleUserSelect(user)}>
                                            <img
                                                src={user.image ? `${apiUrl}/skripte/uploads/${user.image}` : require('../../img/default.png')}
                                                alt="Profile"
                                            />
                                            @{user.username} - {user.firstName} {user.lastName}
                                        </li>
                                    ))
                                }
                            </ul>
                            <button className="primary-button" type="button" onClick={handleSubmit}>Create</button>
                        </form>
                        <button className="red-button" onClick={DiscardModal}>Discard</button>
                    </div>
                </Modal>
                <hr />
                {loading ? (
                    <p>Loading...</p>
                ) : groups.length > 0 ? (
                    <p>
                        {groups.map(group => (
                            <button className="action-button-3" key={group.id} onClick={() => handleGroupClick(group.id)}>
                                {group.name}
                            </button>
                        ))}
                    </p>
                ) : (
                    <p>No groups found.</p>
                )}
            </div>
        </div>

    );

}
export default GroupFriends;