import React from 'react';
import { useAuth } from './AuthContext';

const Header = () => {
  const { user } = useAuth(); 

  return (
    
    <div className="header">
        <h1>Welcome {user.ime}</h1>
    </div>
    
  );
}

export default Header;
