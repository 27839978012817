import React, { useState } from 'react';
import { useAuth } from '../components/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  //Prijava
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://seafold.fsb-esport.net/skripte/account.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();
    console.log(data);
    if (data.success) {
      login(data.user);
      navigate('/');
    } else {
      alert('Neuspješna prijava!');
    }
  };

  //Forgot password
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    // Slanje zahtjeva za zaboravljenu lozinku
    const response = await fetch('https://seafold.fsb-esport.net/skripte/password_reset.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `email=${forgotEmail}`
    });

    const data = await response.json();
    if (data.success) {
      alert('If your email is in our database, you will receive a password reset link.');
    } else {
      alert('There was a problem processing your request. Please try again.');
    }
    setShowForgotPassword(false);
  };

  return (
    <div class="login">
    <div className="logo-big">
      <img src={require('../img/logo.png')} alt="Logo" />
    </div>
    <div class="login-form">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button className="primary-button" type="submit">Login</button>
      </form>
      
      {showForgotPassword && (
        <form onSubmit={handleForgotPassword}>
          <input
            type="email"
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
            placeholder="Enter your email"
          />
          <button className="action-button-2" type="submit">Send Reset Link</button>
        </form>
      )}

      <div class="login-menu">
        {!showForgotPassword && (
          <p><button className="action-button-2" onClick={() => setShowForgotPassword(true)}>Forgot password?</button></p>
        )}
        <p><Link to="/register" className="action-button-2">Register</Link></p>
      </div>
      
    </div>
    </div>
  );
};

export default Login;
