import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
        Copyright &copy; {currentYear}. | Design and development - <span>Filip Paić</span>
    </div>
  );
}

export default Footer;
