import React from 'react';
import { useAuth } from '../components/AuthContext';
import { ToastContainer } from 'react-toastify';

import Navbar from '../components/navbar';
import Actions from '../components/community/actions';
import Activity from '../components/community/activity';
import GroupInfo from '../components/community/group-info';
import Menu from '../components/community/menu';


import '../css/style.css';

const CommunityGroup = () => {
  const { user } = useAuth();

  return (
    
    <div>
      {user && <Navbar />}
    <main className="community-main">
      <div className="content">
        <GroupInfo />
        <Actions />
      </div>
      <div className="content">
        <Menu />
        <h2>Recent activity</h2>
        <Activity />
      </div>
    </main>   
    <ToastContainer />
    </div>
  );
}

export default CommunityGroup;
