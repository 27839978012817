import React, {useState} from 'react';
import { useAuth } from './AuthContext';

import Account from './account';

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <nav>
      <div className="logo">
        <img src={require('../img/logo.png')} alt="Logo" />
      </div>
      <div className="menu">
        <ul>
          <li><a href="/">Home</a></li>
        </ul>
      </div>
      <div className="profile" onClick={openModal}>
        {user && <span>{user.ime}</span>}
        {user && <img 
          src={user.image ? `${apiUrl}/skripte/uploads/${user.image}` : require('../img/default.png')} 
          alt="Profile" 
        />}
      </div>
      <Account isOpen={isModalOpen} closeModal={closeModal} />
    </nav>
  );
}

export default Navbar;
