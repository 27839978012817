import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';
import Modal from 'react-modal';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';

import { AiFillCloseCircle } from "react-icons/ai";

import 'react-big-calendar/lib/css/react-big-calendar.css';

// Postavite moment da koristi vremensku zonu Europe/Zagreb
moment.tz.setDefault("Europe/Zagreb");
moment.locale('hr');
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  //Dizajn modala
  const modalStyle = {
    content: {
      width: '40%',
      height: '60%',
      border: '1px solid #1488df',
      background: '#0d1b2a',
      overflow: 'auto',
      borderRadius: '10px',
      outline: 'none',
      padding: '20px',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
  };

  const [currentView, setCurrentView] = useState('month');
  
  //Style eventa
  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {};
    if (currentView === 'agenda') {
      style = {
        backgroundColor: '#343a40', 
        color: 'white', 
        border: 'none',
      };
    } else if (currentView === 'month') {
      style = {
        backgroundColor: event.eventColor || '#52D3D8',
        color: 'black', 
        border: 'none',
      };
    }

    return { style };
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [eventTitle, setEventTitle] = useState('');
  const [eventStart, setEventStart] = useState('');
  const [eventEnd, setEventEnd] = useState('');
  const [eventStartDB, setEventStartDB] = useState('');
  const [eventEndDB, setEventEndDB] = useState('');
  const [repeatFrequency, setRepeatFrequency] = useState('');
  const [endRepeatDate, setEndRepeatDate] = useState('');
  const [eventColor, setEventColor] = useState('#52D3D8');

  //Dohvacanje iz baze podataka
  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line
  }, []);

  const fetchEvents = () => {
    axios.post(`${apiUrl}/skripte/agenda.php`, { action: 'fetch', username: user.username })
      .then(response => {
        const fetchedEvents = response.data;
        const newEvents = [];
  
        fetchedEvents.forEach(event => {
          let startDate = new Date(event.start);
          let endDate = new Date(event.end);
          const repeatFrequency = event.repeatFrequency;
          let endRepeatDate = event.endRepeatDate ? new Date(event.endRepeatDate) : null;
          const durationDays = (endDate - startDate) / (1000 * 3600 * 24);
  
          if (!repeatFrequency) {
            newEvents.push({
              ...event,
              start: startDate,
              end: endDate,
              allDay: false,
            });
          } else {
            while (startDate < endRepeatDate) {
              newEvents.push({
                ...event,
                start: new Date(startDate),
                end: new Date(endDate),
                allDay: false,
              });
  
              switch (repeatFrequency) {
                case 'daily':
                  startDate.setDate(startDate.getDate() + durationDays + 1);
                  endDate.setDate(endDate.getDate() + durationDays + 1);
                  break;
                case 'weekly':
                  startDate.setDate(startDate.getDate() + 7);
                  endDate.setDate(endDate.getDate() + 7);
                  break;
                case 'monthly':
                  startDate.setMonth(startDate.getMonth() + 1);
                  endDate = new Date(startDate.getTime());
                  endDate.setDate(endDate.getDate() + durationDays);
                  break;
                default:
                  break;
              }
            }
          }
        });
  
        setEvents(newEvents);
      })
      .catch(error => {
        console.error('Error fetching events:', error);
      });
  };
  
  //Klik na event u kalendaru
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventTitle(event.title);
    setEventStartDB(moment(event.start).format('YYYY-MM-DDTHH:mm'));
    setEventEndDB(moment(event.end).format('YYYY-MM-DDTHH:mm'));
    setRepeatFrequency(event.repeatFrequency || '');
    setEndRepeatDate(event.endRepeatDate ? moment(event.endRepeatDate).format('YYYY-MM-DD') : '');
    setDetailsModalIsOpen(true);
    setEventColor(event.eventColor);
  };
  
  //Save event
  const handleSubmit = () => {
    if (!eventTitle.trim()) {
      toast.error('Title cannot be empty!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    let startDate = new Date(eventStart);
    let endDate = new Date(eventEnd);

    // Provjerava jesu li datumi valjani
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      toast.error('Please enter valid start and end dates.', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    // Provjerava jesu li datumi u ispravnom rasponu
    if (endDate <= startDate) {
      toast.error('End date must be after start date.', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    // Spremanje u bazu podataka
    const saveEvent = (action, eventData) => {
      axios.post(`${apiUrl}/skripte/agenda.php`, { ...eventData, action: action })
      .then((response) => {
        fetchEvents();
        toast.success('Event added!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
        .catch(error => {
          toast.error('Error: Not saved!', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
    saveEvent('add', {
      
      username: user.username,
      title: eventTitle,
      start: eventStart,
      end: eventEnd,
      repeatFrequency: repeatFrequency,
      endRepeatDate: endRepeatDate,
      eventColor: eventColor, 
    });

    const newEvents = [];
  
    setEvents([...events, ...newEvents]);
    ClearInputs();
    closeModal();
    fetchEvents();
  }; 

  //Delete event
  const handleDeleteClick = () => {
    // Prikazuje dijalog s potvrdom
    const isConfirmed = window.confirm("Are you sure you want to delete this event?");
  
    if (isConfirmed) {
      handleDelete();
    }
  };

  const handleDelete = () => {
    if (selectedEvent && selectedEvent.id) {
      axios.post(`${apiUrl}/skripte/agenda.php`, {
        action: 'delete',
        id: selectedEvent.id
      })
      .then(response => {
        ClearInputs();
        closeDetailsModal();
        fetchEvents(); 
        toast.success('Event deleted!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch(error => {
        console.error('Error deleting event:', error);
        toast.error('Error deleting event!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  };
  
  //Update event
  const handleUpdate = () => {
    if (!eventTitle.trim()) {
      toast.error('Title cannot be empty!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    let startDate = new Date(eventStartDB);
    let endDate = new Date(eventEndDB);

    // Provjerava jesu li datumi valjani
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      toast.error('Please enter valid start and end dates.', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    // Provjerava jesu li datumi u ispravnom rasponu
    if (endDate <= startDate) {
      toast.error('End date must be after start date.', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (selectedEvent && selectedEvent.id) {
      axios.post(`${apiUrl}/skripte/agenda.php`, {
        action: 'update',
        id: selectedEvent.id,
        title: eventTitle,
        start: eventStartDB,
        end: eventEndDB,
        repeatFrequency: repeatFrequency,
        endRepeatDate: endRepeatDate,
        eventColor: eventColor,
      })
      .then(response => {
        ClearInputs();
        toast.success('Event updated!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        closeDetailsModal();
        fetchEvents();
      })
      .catch(error => {
        toast.error('Error updating event!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
    const newEvents = [];
  
    setEvents([...events, ...newEvents]);
    closeModal();
    fetchEvents();
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
  }
  const DiscardModal = () => {
    setModalIsOpen(false);
    ClearInputs();
  }

  const closeDetailsModal = () => {
    setDetailsModalIsOpen(false);
    ClearInputs();
  };

  useEffect(() => {
    if (eventStart) {
      setEventEnd(eventStart);
    }
  }, [eventStart]);

  const ClearInputs = () => {
    setEventTitle('');
    setEventStart('');
    setEventEnd('');
    setEventColor('');
    setRepeatFrequency('');
    setEndRepeatDate('');
  };

  return (
    <div>
      <button className="primary-button" onClick={openModal}>Add Event</button>
      <Modal 
        isOpen={modalIsOpen} 
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeModal} 
        contentLabel="Add Event"
        style={modalStyle}
      >
        <button onClick={closeModal} className="action-button" style={{right: '20px', fontSize: '35px', position: 'absolute'}}><AiFillCloseCircle /></button>
        <div className="modal-content">
        <h2>Add Event</h2>
        <form>
          <input type="text" placeholder="Title of event" value={eventTitle} onChange={e => setEventTitle(e.target.value)} />
          <div class="modal-group">
            <p>from:</p>
            <input type="datetime-local" value={eventStart} onChange={e => setEventStart(e.target.value)} />
            <p>to:</p>
            <input type="datetime-local" value={eventEnd} onChange={e => setEventEnd(e.target.value)} />
          </div>
          <p>Repeat</p>
          <div className="modal-group">
            <select value={repeatFrequency} onChange={e => setRepeatFrequency(e.target.value)}>
              <option value="">None</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
            <p>until:</p>
            <input type="date" placeholder="End Repeat Date" value={endRepeatDate} onChange={e => setEndRepeatDate(e.target.value)} />
          </div>
          <p>Color:</p>
          <select value={eventColor} onChange={e => setEventColor(e.target.value)} >
            <option value="#52D3D8">Green</option>
            <option value="#F28585">Red</option>
            <option value="#86A7FC">Blue</option>  
            <option value="#FFCC70">Yellow</option>
          </select>
          <button className="primary-button" type="button" onClick={handleSubmit}>Save</button>
        </form>
        <button className="red-button" onClick={DiscardModal}>Discard</button>
        </div>
      </Modal>

      <div style={{ height: 500 }}>
        <Calendar
          localizer={localizer}
          events={events}
          eventPropGetter={eventStyleGetter}
          onView={handleViewChange}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '65vh', zindex: '0' }}
          onSelectEvent={handleEventClick}
          views={['month', 'agenda']}
          defaultView='month'
          formats={{
            timeGutterFormat: 'HH:mm',
            agendaTimeFormat: 'HH:mm',
            eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
              localizer.format(start, 'HH:mm', culture) + ' – ' + localizer.format(end, 'HH:mm', culture),
            agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
              localizer.format(start, 'HH:mm', culture) + ' – ' + localizer.format(end, 'HH:mm', culture),
            dayHeaderFormat: 'dddd, MMMM DD, YYYY',
            dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
              localizer.format(start, 'MMMM DD', culture) + ' – ' + localizer.format(end, 'MMMM DD', culture),
          }}
        />
      </div>

      <Modal
        isOpen={detailsModalIsOpen}
        onRequestClose={closeDetailsModal}
        shouldCloseOnOverlayClick={false}
        style={modalStyle}
        contentLabel="Event Details"
      >
        <button onClick={closeDetailsModal} className="action-button" style={{right: '20px', fontSize: '35px', position: 'absolute'}}><AiFillCloseCircle /></button>
        <div className="modal-content">
        <h2>Update Event</h2>
        <form>
          <input type="text" placeholder="Title of event" value={eventTitle} onChange={e => setEventTitle(e.target.value)} />
          <div className="modal-group">
            <p>from:</p>
            <input type="datetime-local" value={eventStartDB} onChange={e => setEventStartDB(e.target.value)} />
            <p>to:</p>
            <input type="datetime-local" value={eventEndDB} onChange={e => setEventEndDB(e.target.value)} />
          </div>
          <p>Repeat</p>
          <div className="modal-group">
            <select value={repeatFrequency} onChange={e => setRepeatFrequency(e.target.value)}>
              <option value="">None</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
            <p>until:</p>
            <input type="date" placeholder="End Repeat Date" value={endRepeatDate} onChange={e => setEndRepeatDate(e.target.value)} />
          </div>
          <p>Color:</p>
          <select onChange={e => setEventColor(e.target.value)} value={eventColor}>
            <option value="#52D3D8">Green</option>
            <option value="#F28585">Red</option>
            <option value="#86A7FC">Blue</option>  
            <option value="#FFCC70">Yellow</option>  
          </select>
          <button className="primary-button" type="button" onClick={handleUpdate}>Update</button>
        </form>
        <div style={{display: 'flex', gap: '10px'}}>
          <button className="red-button" onClick={handleDeleteClick}>Delete Event</button>
        </div>   
        </div>
      </Modal>

    </div>
  );
};

export default MyCalendar;
