import React, { useState } from "react";
import Modal from 'react-modal';

import { FaMapMarkedAlt, FaPollH } from "react-icons/fa";
import { IoSettings, IoPeople } from "react-icons/io5";
import { BsCalendarEventFill } from "react-icons/bs";

import TripPlaner from "./trip-planer";

const Actions = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    //Dizajn modala
    const modalStyle = {
        content: {
            width: '90%',
            height: '90%',
            border: '1px solid #1488df',
            background: '#0d1b2a',
            overflow: 'auto',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
    };

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => {
        setModalIsOpen(false);
    }

    return (
        <div>
            <button className="action-button-3" onClick={openModal}><FaMapMarkedAlt /> Trip planner</button>
            <Modal
                isOpen={modalIsOpen}
                shouldCloseOnOverlayClick={false}
                onRequestClose={closeModal}
                contentLabel="Add Event"
                style={modalStyle}
            >
                <TripPlaner />
            </Modal>
            <button className="action-button-3"><BsCalendarEventFill /> Add new event</button>
            <button className="action-button-3"><FaPollH /> Add new poll</button>
            <hr />
            <button className="action-button-3"><IoSettings /> Group settings</button>
            <button className="action-button-3"><IoPeople /> Members</button>
        </div>
    );

}
export default Actions;