import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function GroupInfo() {
    const { groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchGroupDetails = async () => {
            try {
                const response = await axios.get(`${apiUrl}/skripte/group-info.php?groupId=${groupId}`);
                if (response.data.success) {
                    setGroup(response.data.group);
                } else {
                    alert(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching group details:', error);
                alert('An error occurred while fetching group details.');
            } finally {
                setLoading(false);
            }
        };

        if (groupId) {
            fetchGroupDetails();
        }
        // eslint-disable-next-line
    }, [groupId]);

    return (
        <div>
            <div className="group-info">
                {loading ? (
                    <p>Loading group data...</p>
                ) : group ? (
                    <div>
                        <h3>{group.name}</h3>
                        <p>{group.description}</p>
                    </div>
                ) : (
                    <p>No group data found.</p>
                )}
            </div>

        </div>
    );

}
export default GroupInfo;