import React, { useState } from 'react';

const Screen2 = ({ tripData, setTripData }) => {
  const [person, setPerson] = useState('');

  const addPerson = () => {
    if (person) {
      setTripData((prevData) => ({
        ...prevData,
        people: [...prevData.people, person],
      }));
      setPerson('');
    }
  };

  return (
    <div>
      <h2>Dodaj Ljude</h2>
      <label>
        Ime:
        <input
          type="text"
          value={person}
          onChange={(e) => setPerson(e.target.value)}
        />
      </label>
      <button onClick={addPerson}>Add New</button>
      <ul>
        {tripData.people.map((p, index) => (
          <li key={index}>{p}</li>
        ))}
      </ul>
    </div>
  );
};

export default Screen2;
