import React from 'react';
import { useAuth } from '../components/AuthContext';
import { ToastContainer } from 'react-toastify';

import Navbar from '../components/navbar';
import GroupFriends from '../components/community/group-friends';
import Activity from '../components/community/activity';

import '../css/style.css';

const Community = () => {
  const { user } = useAuth();

  return (
    
    <div>
      {user && <Navbar />}
    <main className="community-main">
      <div className="content">
        <GroupFriends />
      </div>
      <div className="content">
        <h2>Recent activity</h2>
        <Activity />
      </div>
    </main>   
    <ToastContainer />
    </div>
  );
}

export default Community;
